import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import styles from './style/insertRewardModal.module.scss';

class InserRewardModal extends Component {
    initialValues = {
        userEmail: this.props.userDetailData?.user_email ?? '',
        userName: this.props.userDetailData?.user_name ?? '',
        activity: '',
        point: 0,
        message: ''

    };

    validationSchema = Yup.object({
        userName: Yup.string()
            .required('User Name is required'),
        userEmail: Yup.string()
            .required('User Email is required'),
        activity: Yup.string()
            .required('Activity is required'),
        point: Yup.number()
            .required('Point is required')
            .integer('Point must be an integer')
            .min(1, 'Point must greater than 1')
            .max(999, 'Point must lower than 1000'),
        message: Yup.string()
            .required("Message is required")
    });

    onSubmit = (values, { setSubmitting }) => {
        this.props.postNewReward(values);
        this.props.closeForm();
        setSubmitting(false);
    };

    render() {
        return (
        <Modal isOpen={this.props.isOpen} toggle={() => this.props.closeForm()}>
            <ModalHeader toggle={() => this.props.closeForm()}>Tambah Reward Point</ModalHeader>
            <Formik
                initialValues={this.initialValues}
                validationSchema={this.validationSchema}
                onSubmit={this.onSubmit}
                >
                {({ isSubmitting }) => (
                    <Form>
                        <ModalBody>
                            <div className={`form-group ${styles.fieldContainer}`}>
                                <label>User Name</label>
                                <Field
                                    type='text'
                                    id="userName"
                                    name="userName"
                                    className="form-control"
                                    disabled={true}
                                />
                            </div>
                            <div className={`form-group ${styles.fieldContainer}`}>
                                <label>User Email</label>
                                <Field
                                    type='text'
                                    id="userEmail"
                                    name="userEmail"
                                    className="form-control"
                                    disabled={true}
                                />
                            </div>
                            <div className={`form-group ${styles.fieldContainer}`}>
                                <label>Activity</label>
                                <Field
                                    type="text"
                                    id="activity"
                                    name="activity"
                                    className="form-control"
                                />
                                <ErrorMessage 
                                    name="activity" 
                                    component="div" 
                                    className="text-danger" 
                                />
                            </div>
                            <div className={`form-group ${styles.fieldContainer}`}>
                                <label>Point</label>
                                <Field 
                                    type="number" 
                                    id="point" 
                                    name="point" 
                                    className="form-control" 
                                />
                                <ErrorMessage 
                                    name="point" 
                                    component="div" 
                                    className="text-danger"
                                />
                            </div>
                            <div className={`form-group ${styles.fieldContainer}`}>
                                <label>Message</label>
                                <Field
                                    as="textarea"
                                    id="message"
                                    name="message"
                                    className="form-control"
                                    maxLength="250"
                                />
                                <ErrorMessage 
                                    name="message" 
                                    component="div" 
                                    className="text-danger" 
                                />
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" onClick={() => this.props.closeForm()} disabled={isSubmitting}>
                                Cancel
                            </Button>
                            <Button type="submit" color="primary" disabled={isSubmitting}>
                                Save
                            </Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
        );
    }
}

InserRewardModal.propTypes = {
    isOpen: PropTypes.any,
    userId: PropTypes.string,
    userDetailData: PropTypes.object,
    closeForm: PropTypes.func,
    postNewReward: PropTypes.func
}

export default InserRewardModal;
