import React, { Component } from "react"
import { Row, Col, Card, CardBody, Button, Table, Pagination, PaginationItem, PaginationLink, Form, FormGroup, Label, Input } from "reactstrap"
// Modal, ModalHeader, ModalBody, ModalFooter
import MetaTags from 'react-meta-tags'
import moment from 'moment'

//Import Breadcrumb
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SweetAlert from 'react-bootstrap-sweetalert';
import { urlWeb } from "../../config";
import cellEditFactory from 'react-bootstrap-table2-editor';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import httprequester from '../../lib/httprequester'
import PropTypes from 'prop-types';
import { withRouter } from 'react-router'

class verifikasiartikel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      totalpages: [],
      alert: false,
      openmodal: false,
      // modal: false,
      message: '',
      urlimage: '',
      id: null,
      listArtikel: {
        "total": 0,
        "perPage": 1,
        "page": 1,
        "lastPage": 1,
        "data": []
      },
      step: '',
      status: 'Terkirim',
      formLink: false,
      idArtikel: '',
      idModerasi: 0,
      loading: false,
      formApproval: false,
      messagesApproval: '',
      actionApproval: '',
      link_artikel: ''
    }
  }

  static propTypes = {
    history: PropTypes.object.isRequired
  }

  terbitkan = (id, status) => {
    this.setState({
      step: id,
      // status: status
    })

    let body = {
      "artikel_id": id,
      "artikel_status": status
    }

    httprequester.postnobody('artikel/moderasi', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          this.setState({
            message: response.data.detailMessage,
            alert: true
          })
          this.getListArtikel()
          // console.log('ds',this.state.listArtikel.data)
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })

    // console.log(this.state.step)
  }

  terbitkanURLPopUp = (id, link) => {
    this.setState({
      idModerasi: id,
      formLink: true,
      link_artikel: link
    })
  }
  terbitkanURL = (e) => {

    this.setState({
      loading: true,
      formLink: false
    })
    let body = {
      "artikel_id": this.state.idModerasi,
      "artikel_image_url": urlWeb + "artikel/" + e
    }

    httprequester.postnobody('artikel/moderasi', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          this.setState({
            message: response.data.detailMessage,
            alert: true,
            formLink: false,
            loading: false
          })
          this.getListArtikel()
          // console.log('ds',this.state.listArtikel.data)
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  check = (id, status) => {
    this.setState({
      step: id,
      // status: status
    })

    let body = {
      "artikel_id": id,
      "artikel_status": status
    }

    httprequester.postnobody('artikel/moderasi', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          this.setState({
            message: response.data.detailMessage,
            alert: true
          })
          this.getListArtikel()
          // console.log('ds',this.state.listArtikel.data)
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })

    // console.log(this.state.step)
  }

  handlechange = (e) => {
    let body = {
      "data": {
        "artikel_status": e.target.value
      },
      "page": 1,
      "limit": 10,
      "order": 'desc'
    }

    this.setState({
      status: e.target.value
    })
    httprequester.postnobody('admin/artikel/submit_list', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          let pages = []

          for (let index = 1; index <= response.data.detailMessage.lastPage; index++) {
            pages.push(index)
          }

          this.setState({
            listArtikel: response.data.detailMessage,
            totalpages: pages,
            page: response.data.detailMessage.page,
            sizePerPage: response.data.detailMessage.perPage,
            // totalPage: response.data.detailMessage.lastPage,
          })

          // console.log('ds',this.state.listArtikel.data)
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  getListArtikel = () => {
    let body = {
      "data": {
        "artikel_status": this.state.status
      },
      "page": this.state.page,
      "limit": this.state.sizePerPage,
      "order": "desc"
    }

    httprequester.postnobody('admin/artikel/submit_list', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          let pages = []

          for (let index = 1; index <= response.data.detailMessage.lastPage; index++) {
            pages.push(index)
          }

          this.setState({
            listArtikel: response.data.detailMessage,
            totalpages: pages,
            page: response.data.detailMessage.page,
            sizePerPage: response.data.detailMessage.perPage,
            // totalPage: response.data.detailMessage.lastPage,
          })

          // console.log('ds',this.state.listArtikel.data)
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }


  handlecancel = () => {
    this.setState({
      alert: false
    })
  }

  approve = () => {
    // console.log('id',id)
    let body = {
      "artikel_id": this.state.idModerasi,
      "artikel_status": (this.state.actionApproval === "Menolak") ? "Ditolak" : "Diterima"
    }
    this.setState({
      loading: true,
      formApproval: false
    })

    httprequester.postnobody('artikel/moderasi', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          this.setState({
            message: response.data.detailMessage,
            alert: true,
            loading: false
          })
          this.getListArtikel()
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  rejectPopUp = (v) => {
    this.setState({
      formApproval: true,
      idModerasi: v.artikel_id,
      messagesApproval: 'Apa Anda Yakin Ingin Menolak Artikel ' + v.artikel_title
    })
  }

  approvalPopUp = (v, action) => {
    this.setState({
      formApproval: true,
      idModerasi: v.artikel_id,
      actionApproval: action,
      messagesApproval: `Apa Anda Yakin Ingin ${action} Artikel ${v.artikel_title}`
    })
  }

  reject = () => {
    // console.log('id', this.state.id)
    let body = {
      "artikel_id": this.state.idModerasi,
      "artikel_status": "Ditolak"
    }

    httprequester.postnobody('artikel/moderasi', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          this.setState({
            message: response.data.detailMessage,
            alert: true
          })
          this.getListArtikel()
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  handlePageClick = page => {
    this.setState({ page: page })

    let body = {
      "data": {
        "artikel_status": this.state.status
      },
      "page": page,
      "limit": this.state.sizePerPage,
      "order": "desc"
    }

    httprequester.postnobody('admin/artikel/submit_list', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          let pages = []

          for (let index = 1; index <= response.data.detailMessage.lastPage; index++) {
            pages.push(index)
          }

          this.setState({
            listArtikel: response.data.detailMessage,
            totalpages: pages,
            page: response.data.detailMessage.page,
            sizePerPage: response.data.detailMessage.perPage,
            // totalPage: response.data.detailMessage.lastPage,
          })

          // console.log('ds',this.state.listArtikel.data)
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  direct = (id) => {
    // this.props.history.push('/detail-artikel')
    this.props.history.push(`verifikasi-artikel/detail/${id}`)
  }

  componentDidMount() {
    this.getListArtikel()
  }

  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Artikel | Ruang Ilmiah</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Artikel" breadcrumbItem="Moderasi Artikel" />
            {
              (this.state.formLink) ?

                <SweetAlert
                  input
                  inputType="text"
                  defaultValue={this.state.idArtikel}
                  title="Isi Link Artikel"
                  validationMsg="Link Artikel Tidak Boleh Kosong"
                  onConfirm={(e) => this.terbitkanURL(e)}
                  onCancel={() => this.setState({ formLink: false })}
                  showCancel={true}
                /> :
                null

            }


            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Form>
                      <FormGroup>
                        {/* <Label for="exampleSelect">
                          Status
                        </Label> */}
                        <Input
                          id="exampleSelect"
                          name="select"
                          type="select"
                          style={{ width: '120px' }}
                          onChange={this.handlechange}
                        >
                          <option value="Terkirim">
                            Terkirim
                          </option>
                          <option value="On progress">
                            On progress
                          </option>
                          <option value="Diterima">
                            Diterima
                          </option>
                          <option value="Ditolak">
                            Ditolak
                          </option>
                          <option value="Diterbitkan">
                            Diterbitkan
                          </option>
                        </Input>
                      </FormGroup>
                    </Form>
                    <br />
                    <Table
                    >
                      <thead>
                        <tr>
                          {/* <th>
                            Foto
                          </th> */}
                          <th>
                            Nama Artikel
                          </th>
                          <th>
                            Penulis
                          </th>
                          <th>
                            Status
                          </th>
                          {
                            (this.state.status === "Diterima" || this.state.status === "Diterbitkan") ?
                              <th>
                                Link Artikel
                              </th> : null
                          }
                          <th>
                            User
                          </th>
                          <th>
                            Action
                          </th>
                        </tr>
                      </thead>
                      {this.state.listArtikel.data && this.state.listArtikel.data.map((v, key) => {
                        return <tbody key={key}>
                          <tr>
                            {/* <td>
                              <div>
                                <img
                                  src={v.artikel_image_url}
                                  alt="sertifikat-img"
                                  title="sertifikat-img"
                                  className="avatar-md"
                                  onClick={() => window.open(v.artikel_image_url, "_blank")}
                                // onClick={(e) => { this.Open(row) }}
                                />
                              </div>
                            </td> */}
                            <td>
                              {v.artikel_title}
                            </td>
                            <td>
                              {v.artikel_penulis}
                            </td>
                            <td>
                              {v.artikel_status}
                            </td>
                            {
                              (this.state.status === "Diterima" || this.state.status === "Diterbitkan") ?
                                <td>
                                  {v.artikel_image_url}
                                </td> : null
                            }
                            <td>
                              {v.created_by}
                            </td>
                            {/* v.artikel_id == this.state.step && */}
                            {v.artikel_status == 'On Progress' ?
                              <td>
                                <Button color="primary" size="sm" onClick={() => this.approvalPopUp(v, 'Menerima')}>
                                  Diterima
                                </Button>
                                &nbsp;
                                <Button color="warning" size="sm" onClick={() => this.approvalPopUp(v, "Menolak")}>
                                  Ditolak
                                </Button>
                                &nbsp;
                                <Button color="secondary" size="sm" onClick={() => this.direct(v.artikel_id)}>
                                  Detail
                                </Button>
                              </td> : v.artikel_status == 'Diterima' ? <td>
                                <Button color="info" size="sm" onClick={() => this.terbitkanURLPopUp(v.artikel_id, v.artikel_image_url)}>
                                  Link Artikel
                                </Button>
                                <Button color="warning" size="sm" onClick={() => this.terbitkan(v.artikel_id, 'Diterbitkan')}>
                                  Diterbitkan
                                </Button>
                              </td> : v.artikel_status == 'Diterbitkan' ?
                                <td>
                                  <Button color="info" size="sm" onClick={() => this.terbitkanURLPopUp(v.artikel_id, v.artikel_image_url)}>
                                    Link Artikel
                                  </Button>
                                </td> : v.artikel_status == 'Ditolak' ?
                                  <td>
                                    <Button color="danger" size="sm" onClick={() => this.check(v.artikel_id, 'On Progress')}>
                                      Cek Ulang
                                    </Button>
                                  </td> : <td>
                                    <Button color="danger" size="sm" onClick={() => this.check(v.artikel_id, 'On Progress')}>
                                      Cek
                                    </Button>
                                  </td>
                            }
                          </tr>
                        </tbody>
                      })}
                    </Table>
                    <div className="col-lg-12 col-sm-12">
                      <Pagination className="pagination pagination-rounded justify-content-end mb-2">
                        <PaginationItem disabled={this.state.page === 1}>
                          <PaginationLink
                            previous
                            href="#"
                            onClick={() => this.handlePageClick(this.state.page - 1)}
                          />
                        </PaginationItem>
                        {this.state.totalpages && this.state.totalpages.map((v, key) =>
                          <PaginationItem active={v === this.state.page} key={key}>
                            <PaginationLink
                              onClick={() => this.handlePageClick(v)}
                              href="#"
                            >
                              {v}
                            </PaginationLink>
                          </PaginationItem>
                        )}
                        <PaginationItem disabled={this.state.page === this.state.totalpages}>
                          <PaginationLink
                            next
                            href="#"
                            onClick={() => this.handlePageClick(this.state.page + 1)}
                          />
                        </PaginationItem>
                      </Pagination>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          {this.state.formApproval ?
            <SweetAlert title={this.state.messagesApproval} onConfirm={() => this.approve()} onCancel={() => this.setState({ formApproval: false })} showCancel={true}>
            </SweetAlert> : ''
          }
          {this.state.alert ?
            <SweetAlert success title={this.state.message} onConfirm={this.handlecancel}>
            </SweetAlert> : ''
          }
          {this.state.loading ? (
            <SweetAlert title="menghubungkan ke server" showConfirm={false} >
            </SweetAlert>
          ) : null}
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(verifikasiartikel)